html,
body {
  background-color: #eeeddf;
  min-height: 100vh;
  min-width: 100vw;
  user-select: none;
  overflow-x: hidden;
}

.bottom-btn.top {
  display: none !important;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.App {
  position: relative;
  font-family: "IBM Plex Mono";
  min-height: 100vh;
}

header {
  text-align: center;
}

header {
  background-image: url("/public/top-paper.png");
  background-size: cover;
  background-position: bottom;
  height: 30vh;
  margin: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
}

header h1 {
  margin: 0;
  padding-top: 4.5vh;
  transform: rotate(1.7deg);
  font-weight: 500;
  font-size: 4vh;
  z-index: 2;
  user-select: text;
}

.content .paper {
  height: 65vh;
  width: 100% !important;
  min-width: 1000px;
  margin: auto;
  position: absolute;
  right: 0;
  left: 0;
  top: 20vh;
  z-index: 1;
}

.content .paper-overlay {
  position: absolute;
  width: 42vh;
  bottom: 0;
  right: -36.6vh;
  left: 0;
  top: 20vh;
  margin: auto;
  z-index: 2;
}

.content .container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 65vh;
  z-index: 2;
  box-sizing: border-box;
  top: 28vh;
}

.content .container .combo {
  display: flex;
  gap: 20px;
  font-size: 1.6vh;
  font-weight: 500;
  user-select: text;
}

.content .container img {
  max-width: 26vh;
}

.content button,
.bottom-btn button {
  transition: 0.2s;
  background-color: rgb(42, 113, 180);
  color: white;
  padding: 20px;
  font-family: "IBM Plex Mono";
  font-weight: 500;
  font-size: 1.6vh;
  box-shadow: 2px 2px 2px 0px #000000;
  cursor: pointer;
}

.content button {
  margin-top: 5vh;
  border: none;
}

.bottom-btn.bottom {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 85vh;
  z-index: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-btn button {
  position: relative;
  font-family: "IBM Plex Sans";
  border: none;
}

.bottom-btn div {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "IBM Plex Sans";
}

.bottom-btn.bottom div {
  position: absolute;
  width: 100px;
  margin-right: -395px;
  text-decoration: none;
}

.bottom-btn.top {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}

.bottom-btn.top button {
  margin-top: unset !important;
}

.content button:active,
.bottom-btn button:active {
  background-color: rgba(42, 113, 180, 0.9);
}

.modal * {
  font-family: "IBM Plex Sans";
  user-select: text;
}

.modal {
  padding: 40px;
  position: absolute;
  z-index: 9999;
  background-color: white;

  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow: auto;
}

.modal .modal-header button {
  display: flex;
  align-items: center;
  gap: 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.8vh;
}

.modal .modal-title {
  margin-top: 8vh;
  font-weight: 600;
  font-size: 6vh;
  margin-left: 43px;
}

.modal .modal-text {
  margin-top: 8vh;
  padding-left: 43px;
  padding-right: 43px;
  box-sizing: border-box;
  font-size: 1.8vh;
  font-weight: 500;
}

@media screen and(min-height: 1081px) {
  .paper-overlay {
    display: none;
  }
}

@media screen and (max-width: 780px) {
  .combo {
    flex-direction: column;
    max-width: 400px;
  }

  header {
    height: 14vh !important;
  }

  header h1 {
    padding-top: 3vh !important;
    font-size: 2vh !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: auto;
  }

  .paper-overlay {
    display: none;
  }

  .paper {
    height: 120vh !important;
    width: 1000px !important;
    top: 9vh !important;
  }

  .container {
    width: 100% !important;
    padding: 0 40px 0 80px !important;
    box-sizing: border-box;
    top: 15vh !important;
  }

  .container img {
    height: 140px !important;
  }

  .bottom-btn.bottom {
    display: none;
  }

  .bottom-btn.top {
    display: flex !important;
  }

  .content button,
  .bottom-btn button {
    padding: 10px;
    font-size: 14px;
  }

  .text-container {
    font-size: 14px;
    min-height: 210px;
  }

  .modal {
    padding: 20px;
  }

  .modal-header {
    font-size: 20px !important;
  }

  .modal svg {
    transform: scale(0.7);
  }

  .modal-title {
    font-size: 24px !important;
  }

  .modal-text {
    font-size: 16px !important;
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 550px) {
  h1 {
    font-size: 20px !important;
    padding: 40px !important;
  }

  .container img {
    height: 140px !important;
  }

  .bottom-btn {
    top: 77vh !important;
  }

  .bottom-btn.bottom div {
    top: -55px;
    right: unset !important;
    margin-right: unset !important;
  }
}

.sds-utils-badge-wrapper {
  z-index: 9999999;
}
